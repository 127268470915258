import logo from './img/Logo.jpg';
import './App.css';
import React, {useEffect} from "react";

function Fotos() {

    useEffect(() => {
        window.getFotos();
    }, []);
    return (
        <div className="App">
            <header>
                <div className="nav">
                    <h2><a href="./">Start</a></h2>
                    <h2><a href="./Regelement">Regelement</a></h2>
                    <img src={logo} className="logo" alt="logo" />
                    <h2><a href="./Fotos">Foto's</a></h2>
                    <h2><a href="./Contact">Contact</a></h2>
                </div>
            </header>
            <div className="main">
                <div id="fotolijst">
                    <p className="loading-icon">&#x21BB;</p>
                    <p className="loading-message">Loading...</p>
                </div>
            </div>
            <footer></footer>
        </div>
    );
}

export default Fotos;
