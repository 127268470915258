import React, { useState } from 'react';
import './Aankondiging.css';
import './Beheeracties';
import {getDatabase, ref, set, remove, onValue} from "firebase/database";
import {initializeApp} from "firebase/app";
import firebaseConfig from "./Config";

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const Aankondiging = () => {
    const [images, setImages] = useState(null);
    const [base64Strings, setBase64Strings] = useState('');
    const [progress, setProgress] = useState(0);

    const handleChange = (e) => {
        const selectedImages = Array.from(e.target.files);
        Promise.all(
            selectedImages.map((image) => {
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result.split(',')[1]);
                    };
                    reader.readAsDataURL(image);
                });
            })
        ).then((base64Array) => {
            setImages(selectedImages);
            setBase64Strings(base64Array);
        });
    };


    function addAankondiging(){
        const databaseRef = ref(database, 'Aankondiging');
        onValue(databaseRef, (snapshot) => {
            console.log(snapshot.val())
            if (snapshot.val() !== null){
                deleteAankondiging();
            }else{
                
            }
        });
        document.getElementById("errora").textContent = "";
        let titel = document.getElementById("tbeschrijving").value;
        let beschrijving = document.getElementById("abeschrijving").value;
        let image = "";
        if (titel !== "" && beschrijving !== ""){
            if (base64Strings.length === 0){
            }else{
                image = base64Strings[0];
            }
            const dbRef = ref(database, 'Aankondiging');
            console.log("check");
            set(dbRef, {
                Titel: titel,
                Beschrijving: beschrijving,
                ImageBase: image,
            });
            //window.location.reload();
        }
        else{
            document.getElementById("errora").textContent = "Gelieve een titel en een beschrijving in te geven";
        }
    }

    function deleteAankondiging(){
        const dbRef = ref(database, 'Aankondiging');

// Remove the item from the database
        remove(dbRef)
    }

    return (
        <div className="aankondiging beheer">
            <h1 className="rglh1">Aankondiging</h1>
            <p>Title(*):</p>
            <input id="tbeschrijving" type="text"/>
            <p>Tekst(*):</p>
            <textarea id="abeschrijving"/>
            <input id="finput" type="file" onChange={handleChange}/>
            <div className="fotosa">
                {images && images.map((image, index) => (
                    <div className="imagefieldsa">
                        <img
                            key={index}
                            src={URL.createObjectURL(image)}
                            alt={`Preview ${index}`}
                            style={{ width: '50%', margin: '5px' }}
                        />
                    </div>
                ))}
            </div>
            <button onClick={addAankondiging}>Nieuwe Aankondiging Opslaan</button>
            <p className="errorm" id="errora"></p>
            <button onClick={deleteAankondiging}>Aankondiging Verwijderen</button>
        </div>
    );
};
export default Aankondiging;