import React from 'react';
import { initializeApp } from "firebase/app";
import firebaseConfig from './Config.js';
import { getDatabase, ref, set, onValue, remove } from "firebase/database";
import {onAuthStateChanged, getAuth} from "firebase/auth";



const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth();

window.createCookie = createCookie;
window.getCookie = getCookie;
window.getDFotos = getDFotos;
window.getDActiviteiten = getDActiviteiten;
window.UpdateRegelement = UpdateRegelement;

function getCookie(name) {
    var cookieName = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return "";
}

let pdf = "";
async function UpdateRegelement() {
    console.log(pdf);
    if(pdf !== ""){
        set(ref(database, 'Regelement/' ), {
            Naam: "Regelement",
            PDFBase: pdf
        })
    }
}

function getDFotos() {
    const databaseRef = ref(database, 'Images');
    onValue(databaseRef, (snapshot) => {
        let value = snapshot.val();
        const dataArray = Object.values(value);
        let lijst = document.getElementById("fotolijst");
        lijst.innerHTML = "";
        dataArray.forEach(foto => {
            console.log(foto);
            let div = document.createElement('div');
            div.className = "flitem";
            const img = document.createElement('img');
            img.src = 'data:image/png;base64,' + foto.ImageBase;
            let para = document.createElement('p');
            let button = document.createElement('button');
            button.className = "df";
            button.innerText = "Verwijder";
            button.onclick = function() {
                deleteFoto(foto.Naam);
            };
            para.innerText = foto.Beschrijving;
            div.appendChild(img);
            div.appendChild(para);
            div.appendChild(button);
            lijst.appendChild(div);
        })
    });
}

function getDActiviteiten() {
    const databaseRef = ref(database, 'Activiteiten');
    onValue(databaseRef, (snapshot) => {
        if (snapshot.val() !== "" && snapshot.val() !== null){
            const activiteitenlijst =  Object.values(snapshot.val());
            let div = document.getElementById('activiteitendiv');
            activiteitenlijst.forEach(activiteit => {
                let titel = activiteit.Titel;
                let titelp = document.createElement('h2');
                titelp.innerText = titel;
                let button = document.createElement('button');
                button.className = "df";
                button.innerText = "Verwijder";
                button.onclick = function() {
                    deleteActiviteit(activiteit.Titel);
                };
                console.log(titelp);
                div.appendChild(titelp);
                div.appendChild(button)
            });
        }
    });
}

function deleteFoto(name){
    remove(ref(database, 'Images/' + name ), {
    });
    window.location.reload();
}

function deleteActiviteit(name){
    remove(ref(database, 'Activiteiten/' + name ), {
    });
    window.location.reload();
}

function createCookie(value) {
    pdf = value;
}