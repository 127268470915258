import Home from './Home';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import React from "react";
import Regelement from "./Regelement";
import Contact from "./Contact";
import Fotos from "./Fotos";
import Beheer from "./Beheer";
import Login from "./Login";
import WWVergeten from "./WachtwoordVergeten";
import DeleteFotos from "./DeleteFotos";
import DeleteActiviteiten from "./DeleteActiviteiten";

const App = () => {
    return (
        <Router>

            {/* Navigation Links */}
            {/* Route Configuration */}
            <Routes>
                {/* Define routes for different pages */}
                <Route path="/" element={<Home />} />
                <Route path="/Regelement" element={<Regelement />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Fotos" element={<Fotos />} />
                <Route path="/Beheer/Info" element={<Beheer />} />
                <Route path="/Beheer/Login" element={<Login />} />
                <Route path="/Beheer/WachtwoorVergeten" element={<WWVergeten />} />
                <Route path="/Beheer/Info/Fotos" element={<DeleteFotos />} />
                <Route path="/Beheer/Info/Activiteiten" element={<DeleteActiviteiten />} />
            </Routes>
        </Router>
    );
}

export default App;
