import logo from './img/Logo.jpg';
import './App.css';
import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import firebaseConfig from './Config.js';
import { getAuth, sendPasswordResetEmail  } from 'firebase/auth';




const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const  WWVergeten = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const handleResetPassword  = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage(`Er is een email verzonden naar ${email} om het wachtwoord te herstellen`);
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };


    return (
        <div className="App">
            <div className="login">
                <h1>Login</h1>
                <img src={logo} className="logo" alt="logo" />
                <div>
                    <p>Email:</p>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <button className={"btnl"} onClick={handleResetPassword }>Wachtwoord herstellen</button>
                <p className={"errorm"} id={"errml"}>{message}</p>
            </div>
        </div>
    );
}

export default WWVergeten;
