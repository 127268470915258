import React, {useEffect, useState} from 'react';
import logo from "./img/Logo.jpg";
import './Regelement.css';
import './Beheeracties';
import {initializeApp} from "firebase/app";
import firebaseConfig from "./Config";
import {onAuthStateChanged, getAuth} from "firebase/auth";

import PhotoUploader from './PhotoUploader';
import RegementBeheer from './RegelementBeheer';
import Aankondiging from "./Aankondiging";
import Activiteiten from "./Activiteiten";

const app = initializeApp(firebaseConfig);
const auth = getAuth();

const Beheer = () => {
    useEffect(() => {
        logincheck();
    }, [])
    function logincheck(){
        onAuthStateChanged(auth, (user) => {
            if (user) {

            } else {
               window.location.href = "./Login"
            }
        });
    }

    return (
        <div className="App">
            <header>
                <div className="nav">
                    <h2><a href="/./">Start</a></h2>
                    <h2><a href="/./Regelement">Regelement</a></h2>
                    <img src={logo} className="logo" alt="logo" />
                    <h2><a href="/./Fotos">Foto's</a></h2>
                    <h2><a href="/./Contact">Contact</a></h2>
                </div>
            </header>
            <div className="main">
                <Aankondiging/>
                <Activiteiten/>
                <RegementBeheer/>
                <PhotoUploader />
            </div>
            <footer></footer>
        </div>
    );
};
export default Beheer;
