import React, { useState } from 'react';
import './Aankondiging.css';
import './Beheeracties';
import {getDatabase, ref, set, remove, onValue, update} from "firebase/database";
import {initializeApp} from "firebase/app";
import firebaseConfig from "./Config";

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const Activiteiten = () => {
    const [progress, setProgress] = useState(0);

    async function addActiviteiten(){
        document.getElementById("errora").textContent = "";
        let titel = document.getElementById("actbeschrijving").value;
        if (titel !== ""){
            const dbRef = ref(database, 'Activiteiten/' + titel);
            console.log("check");
            try {
                await set(dbRef, {
                    Titel: titel
                });
                window.location.reload();
            } catch (error) {
                console.error('Update failed:', error);
            }

        }
        else{
            document.getElementById("errora").textContent = "Gelieve een activiteit in te geven";
        }
    }

    function redirectToActiviteiten() {
        window.location.href = '/Beheer/Info/Activiteiten';
    }

    return (
        <div className="aankondiging beheer">
            <h1 className="rglh1">Activiteiten</h1>
            <p>Activiteit(*):</p>
            <input id="actbeschrijving" type="text"/>
            <p></p>
            <button onClick={addActiviteiten}>Nieuwe Activiteit Opslaan</button>
            <p className="errorm" id="errora"></p>
            <button onClick={redirectToActiviteiten}>Activiteiten Verwijderen</button>
        </div>
    );
};
export default Activiteiten;